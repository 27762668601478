import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getH1 } from '../../../SearchResults/utils/meta';
import { getCurrentLocale } from '../../../../utils/language';
import './styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addProLead, setGenericEvent, setConnectionClick } from '../../../../store/actions/dataLayer';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import AddressModal from '../../../../components/AddressModal';
import ContactForm from '../../../../components/ContactForm';
import DescriptionBox from '../../../../components/DescriptionBox';
import FormButtonArray from '../../../../components/FormButtonArray';
import { getAddress } from '../../../../utils/commonHelper';

import {languagesEnglish} from '../../../../tppServices/translations/languageConstants';
import { getMessages } from '../../../../tppServices/translations/messages';

const BrandedHeaderV2 = (props) => {

  const initialState = {
    contactOpen: false,
    locationOpen: false,
    showPhone: false,
  };

  const [state, setState] = useState(initialState);
  const messages = getMessages();

  const getCallNowMessage = () => {
    const { address, intl: { formatMessage: t } } = props;
    return state.showPhone ? address.phone : t(messages.dealerContact.callNow);
  };

  const onAddressClose = () => {
    setState(prevState => (
      {
        ...prevState,
        locationOpen: false,
        showPhone: false
      }
    )
    );
  };

  const onAddressOpen = () => {
    const { ownerId } = props;
    setState(prevState => (
      {
        ...prevState,
        locationOpen: true,
      }
    )
    );
    props.addProLead({id: 'pro get directions',partyId: ownerId });
  };

  const onContactDealerClose = () => {
    setState(prevState => (
      {
        ...prevState,
        contactOpen: false,
      }
    )
    );
  };

  const onContactDealerOpen = () => {
    const { ownerId } = props;
    setState(prevState => (
      {
        ...prevState,
        contactOpen: true,
        locationOpen: false,
      }
    )
    );
    props.addProLead({id: 'pro contact dealer', partyId: ownerId});
  };

  const onCallClick = () => {
    const { ownerId } = props;
    setState(prevState => (
      {
        ...prevState,
        showPhone: true,
      }
    )
    );
    props.addProLead({id: 'view pro phone', partyId: ownerId});
  };

  const onVisitWebsiteClick = () => {
    const { ownerId } = props;
    props.setConnectionClick(ownerId, 'website referral');
    props.setGenericEvent('exit links', 'website referral', ownerId);
  };

  const filterDescription = (description, multiLangDescriptions, languagesEnglish)  => {
    const locale = getCurrentLocale();
    if (multiLangDescriptions[`${languagesEnglish[locale]}Description`]) {
      return multiLangDescriptions[`${languagesEnglish[locale]}Description`];
    }

    return description;
  };

  const showAddress = (address) => {
    return address.city || address.country ||
    address.countrySubDivisionCode || address.postalCode || address.street ? true : false;
  };

  const getNavigationOptions = () => {
    const { mode, intl: { formatMessage: t } } = props;
    const options = [];

    const listingMessage = (mode === BrandedHeaderV2.SEARCH_TYPE.boats)
      ? t(messages.header.boatsForSale) : t(messages.header.enginesForSale);

    options.push(
      {
        id: 'branded-header-v2-listing-anchor',
        text: listingMessage,
        href: '#right-content'
      },
    );

    options.push(
      {
        id: 'branded-header-v2-contact-button',
        text: t(messages.dealerContact.contactDealer),
        onClick: onContactDealerOpen
      },
    );

    options.push(
      {
        id: 'branded-header-v2-address-button',
        text: t(messages.dealerContact.viewAddress),
        onClick: onAddressOpen
      },
    );

    return options;
  };

  const getButtons = () => {
    const { address, intl: { formatMessage: t } } = props;
    let buttons = [];
    if (address.phone) {
      buttons.push(
        {
          icon: 'call',
          isAnchor: true,
          href: `tel:${(address.phone)}`,
          message: getCallNowMessage(),
          target: '_self',
          onClick: onCallClick,
          class: 'secondary'
        }
      );
    }
    buttons.push(
      {
        icon: 'email',
        message: t(messages.dealerContact.contactDealer),
        onClick: onContactDealerOpen,
      }
    );
    return buttons;
  };

  const { bannerPath, description, multiLangDescriptions,
    logoPath, displayName, seoParams, portal, address, ownerId } = props;

  // Render current locale description
  const langDescription = filterDescription(description, multiLangDescriptions, languagesEnglish);

  const navigationOptions = getNavigationOptions();
  const { contactOpen, locationOpen } = state;
  const showAdressModal = showAddress(address);
  const addressProps = {
    address,
    leadId: ownerId,
    modal: true,
    onClose: onAddressClose,
    onCallClick: onCallClick,
    onContactDealerOpen: onContactDealerOpen,
    onVisitWebsiteClick: onVisitWebsiteClick,
    open: true,
  };

  return (
    <div className="branded-header-v2">
      {
        logoPath && <div className={'logo-container'}>
          <div className="logo-frame">
            <div className="logo" style={{ backgroundImage: `url(${encodeURI(logoPath)})` }} />
          </div>
        </div>
      }
      { locationOpen  && showAdressModal &&
        <AddressModal {...addressProps} />
      }
      { contactOpen &&
        <ContactForm
          open={true}
          itemId={Number(ownerId)}
          leadType={ContactForm.TYPES.party}
          onClose={onContactDealerClose}
          mode={ContactForm.MODES.modal}
          contactSubmitTrack
        />
      }
      <div className="navigation-options">
        { navigationOptions.map((option) => (
          <a id={option.id} href={option.href} onClick={option.onClick} key={option.id} >
            {option.text}
          </a>
        ))
        }
      </div>
      { bannerPath &&
        <div className="banner-container" >
          <div className="banner">
            <div className="banner-logo" style={{ backgroundImage: `url(${encodeURI(bannerPath)})` }}/>
          </div>
        </div>
      }
      {  (displayName || langDescription) &&
        <div className={classnames('information-container')}>
          { displayName &&
              <h1>{getH1({params: seoParams, portal, isBranded: true, displayName})}</h1>}
        </div>
      }
      <div className={classnames('dealer-contact-area-v2')} >
        <div className="dealer-address">
          <span>{getAddress(address)}</span>
        </div>
        { langDescription && <div className="party-description-container">
          <DescriptionBox dataName="party-description" showMore data={langDescription} />
        </div>
        }
        <FormButtonArray buttons={getButtons()} />
      </div>
    </div>
  );
};

BrandedHeaderV2.SEARCH_TYPE = {
  boats: 'boats',
  engines: 'engines',
};

BrandedHeaderV2.defaultProps = {
  bannerPath: '',
  description: '',
  multiLangDescriptions: {},
  logoPath: '',
  name: '',
  seoParams: {},
  portal: '',
  params: '',
  address: {},
  mode: BrandedHeaderV2.SEARCH_TYPE.boats,
};

BrandedHeaderV2.propTypes = {
  bannerPath: PropTypes.string,
  description: PropTypes.string,
  multiLangDescriptions: PropTypes.shape(),
  logoPath: PropTypes.string,
  displayName: PropTypes.string,
  seoParams: PropTypes.object,
  portal: PropTypes.string,
  params: PropTypes.string,
  address: PropTypes.shape({
    city: PropTypes.string,
    coordinates: PropTypes.arrayOf(PropTypes.number),
    country: PropTypes.string,
    countrySubDivisionCode: PropTypes.string,
    phone: PropTypes.string,
    postalCode: PropTypes.string,
    street: PropTypes.string,
    website: PropTypes.string,
  }),
  addProLead: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  setGenericEvent: PropTypes.func.isRequired,
  ownerId: PropTypes.string.isRequired,
  setConnectionClick: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(Object.keys(BrandedHeaderV2.SEARCH_TYPE))
};

const mapStateToProps = state => {
  return {
    ownerId: get(state.app, 'params.ownerId', ''),
  };
};

export default connect(mapStateToProps,
  dispatch => bindActionCreators({ addProLead, setGenericEvent, setConnectionClick }, dispatch)
)(injectIntl(BrandedHeaderV2));
