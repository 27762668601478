import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { normalizeString } from '@dmm/lib-common/lib/formatting';
import { hyphenateUrlComponents } from '../../../../../../utils/urlHelpers/boats';
import get from 'lodash/get';
import find from 'lodash/find';
import FilterModel from '../FilterModel';
import { getBoatsConstants } from '../../../../../../constants/boats';

export default class FilterModels extends PureComponent {
  render() {
    const {
      makes = [],
      models = [],
      position,
      makeModels = {},
      handleDataChange,
      params,
      pageType
    } = this.props;
    return makes.reduce((filters, make) => {
      const slugMake = normalizeString(hyphenateUrlComponents(make.value));
      const selectedMakeModels = find(models, ['value', make.value]);
      if (get(makeModels, slugMake)) {
        return [
          ...filters,
          <FilterModel
            key={slugMake}
            make={make}
            models={selectedMakeModels ? selectedMakeModels.model : []}
            makeModels={makeModels}
            position={position}
            handleDataChange={handleDataChange}
            params={params}
            pageType={pageType}
          />
        ];
      }
      return filters;
    }, []);
  }
}

FilterModels.propTypes = {
  handleDataChange: PropTypes.func.isRequired,
  makeModels: PropTypes.object,
  makes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string
    })
  ),
  models: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string
    })
  ),
  params: PropTypes.shape({
    modal: PropTypes.string
  }).isRequired,
  position: PropTypes.string,
  pageType: PropTypes.oneOf(Object.values(getBoatsConstants().PAGE_TYPES))
};
