import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import includes from 'lodash/includes';
import {ToolSetOptions, ToolSetOptionsItem} from '../../../../../components/ToolSet';
import Link from '../../../../../components/SEO/Link';
import {toHTMLId} from '../../../../../utils/commonHelper';

import * as urlHelpers from '../../../../../utils/urlHelpers/boats';
import {getBoatsConstants} from '../../../../../constants/boats/index';
import {setGenericEventClean, setSearchTrackingClean} from '../../../../../store/actions/dataLayer';
import {yieldToMain} from '../../../../../utils';
import {unslugify} from '../../../../../utils/urlHelpers/shared';
import { getMessages } from '../../../../../tppServices/translations/messages';

const FuelFilterItem = ({ facet, messages, selectedFuelTypes, handleToggleFuelType, updateFuelTypeList, params }) => {
  const intl = useIntl();
  const t = intl.formatMessage;
  const fuelName = t(messages.fuelTypeFacetText[facet.value]);
  let unslugifiedFacet = unslugify(get(facet, 'urlId', facet.value), '+', '-');
  unslugifiedFacet = unslugify(unslugifiedFacet, '--', '-');
  const nameId = toHTMLId(unslugifiedFacet);
  const mappedUrlId = getBoatsConstants().FUEL_TYPE_MAPPING.urlId[unslugifiedFacet] || unslugifiedFacet;

  return (
    <ToolSetOptionsItem
      type="checkbox"
      key={`fuel-${nameId}`}
      id={`fuel-${nameId}`}
      name={`fuel-${nameId}`}
      value={facet.value}
      selected={includes(selectedFuelTypes, mappedUrlId)}
      onClick={() => handleToggleFuelType(mappedUrlId)}
    >
      <Link
        className={`class-${nameId}-link`}
        href={urlHelpers.generateSearchPath(
          { fuelType: updateFuelTypeList(facet.value) },
          params,
          true,
        )}
        onClick={(e) => e.preventDefault()}
      >
        {fuelName}
      </Link>
    </ToolSetOptionsItem>
  );
};

FuelFilterItem.propTypes = {
  facet: PropTypes.shape({
    count: PropTypes.number,
    urlId: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  messages: PropTypes.object.isRequired,
  selectedFuelTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleToggleFuelType: PropTypes.func.isRequired,
  updateFuelTypeList: PropTypes.func.isRequired,
  params: PropTypes.shape({
    fuelType: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string
    ])
  }).isRequired
};

const FilterFuelType = ({
  handleDataChange,
  fuelTypes,
  params,
  selectedFuelTypes,
  position
}) => {
  const [fuelTypeFilters, setFuelTypeFilters] = useState(selectedFuelTypes || []);
  const intl = useIntl();
  const t = intl.formatMessage;
  const messages = getMessages();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedFuelTypes !== undefined) {
      setFuelTypeFilters(selectedFuelTypes);
    }
  }, [selectedFuelTypes]);

  const updateFuelTypeList = (fuelType) => {
    if (includes(fuelTypeFilters, fuelType)) {
      return fuelTypeFilters.filter(fuel => fuel !== fuelType);
    }
    return [...fuelTypeFilters, fuelType];
  };

  const handleToggleFuelType = async (fuelType) => {
    const newFuelTypes = updateFuelTypeList(fuelType);
    if (fuelTypeFilters.length > newFuelTypes.length) {
      setGenericEventClean(`fuel type removed - ${fuelType}`);
    } else {
      setSearchTrackingClean({ fuelType: [fuelType] });
    }

    setFuelTypeFilters(newFuelTypes);
    await yieldToMain();
    if (position !== 'mobile') {
      dispatch({ type: 'GET_DATA_LOADING' });
      await yieldToMain();
    }
    handleDataChange('fuelType', newFuelTypes);
  };

  const fuelFilters = fuelTypes
    .filter(facet => facet.count)
    .sort((facetA, facetB) => {
      const fuelNameA = t(messages.fuelTypeFacetText[facetA.value]);
      const fuelNameB = t(messages.fuelTypeFacetText[facetB.value]);
      return fuelNameA > fuelNameB ? 1 : -1;
    })
    .map(facet => (
      <FuelFilterItem
        key={facet.value}
        facet={facet}
        messages={messages}
        t={t}
        selectedFuelTypes={fuelTypeFilters}
        handleToggleFuelType={handleToggleFuelType}
        updateFuelTypeList={updateFuelTypeList}
        params={params}
      />
    ));

  return (
    <div className="search-filter fuel-type">
      {fuelTypes.length ? (
        <ToolSetOptions>
          {fuelFilters}
        </ToolSetOptions>
      ) : null}
    </div>
  );
};

FilterFuelType.propTypes = {
  handleDataChange: PropTypes.func.isRequired,
  fuelTypes: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number,
    urlId: PropTypes.string,
    value: PropTypes.string
  })),
  params: PropTypes.shape({
    fuelType: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string
    ])
  }),
  selectedFuelTypes: PropTypes.arrayOf(PropTypes.string),
  position: PropTypes.string,
};

export default FilterFuelType;
