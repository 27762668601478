import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { frontloadConnect } from 'react-frontload';
import { withCookies, Cookies } from 'react-cookie';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import DefaultLayout from '../../layouts/Default';
import * as actions from '../../store/actions';
import ErrorMessage from '../../components/ErrorMessage';
import * as utils from '../../store/utils';
import MainContent from '../SearchResults/MainContent';
import { generateSearchPath } from '../../utils/urlHelpers/boats';
import { getParamsFromLocationAndHistoryQueryString } from '../../utils/featureToggles';
import { PortalConfigContext } from '../../config/portal';
import {getPartyBrandedSRP, getPartyEnginesBrandedSRP, getPartySearchUrl} from '../../utils/urlHelpers/party';
import {runOnce} from '../../utils/runOnceHelper';
import {getCommonBoatParsers} from '../../utils/urlHelpers/boatsConstantsParsers';

const ACTIVE_PARTY = 'ACTV';
const SUSPENDED_PARTY = 'SUSPD';

class BrandedSearch extends PureComponent {
  isCanonical() {
    const { location: { pathname }, partyDetails, spotlightDetails } = this.props;
    let brandedSrpPath = pathname;
    if (!brandedSrpPath.endsWith('/')) {
      brandedSrpPath += '/';
    }
    const salesRep = get(spotlightDetails, 'party.details') ? {
      normalizedName: get(spotlightDetails, 'party.details.normalizedBrokerName'), id: get(spotlightDetails, 'party.id')
    } : null;
    const partyBrandedSRP = getPartyBrandedSRP(partyDetails, false, null, salesRep);
    const properBrandedUrl = brandedSrpPath.includes(partyBrandedSRP);
    return properBrandedUrl;
  }

  render() {
    const { location: { pathname }, partyDetails, spotlightDetails } = this.props;
    const isLowerCase = pathname && pathname === pathname.toLowerCase();
    if (utils.isServer() && this.props.success && !isLowerCase) {
      return <Redirect to={{ pathname: pathname.toLowerCase() }} {...this.props} />;
    }

    const pageParam = get(this.props, 'match.params.page', '');
    const maxPages = get(this.context, 'pages.searchResults.pagination.maxPages', 357);
    const {parsePageParams} = getCommonBoatParsers(null, this.context);
    const { page } = parsePageParams(pageParam);

    if (page > maxPages) {
      return (
        <DefaultLayout {...this.props} pageType="BrandedSearch">
          <ErrorMessage {...this.props} message="404" />
        </DefaultLayout>
      );
    }
    const emptyPartyDetails = isEmpty(partyDetails);
    const isNotCanonical = !emptyPartyDetails && pathname && !this.isCanonical();
    const disabledParty = !emptyPartyDetails && (partyDetails.statusId !== ACTIVE_PARTY && partyDetails.statusId !== SUSPENDED_PARTY);
    const boom = this.props.isBoom;
    if (disabledParty || boom) {
      return <Redirect to={getPartySearchUrl({})} />;
    }
    if (utils.isServer() && isNotCanonical && this.props.success) {
      const salesRep = get(spotlightDetails, 'party.details') ? {
        normalizedName: get(spotlightDetails, 'party.details.normalizedBrokerName'), id: get(spotlightDetails, 'party.id')
      } : null;
      return <Redirect
        to={getPartyBrandedSRP(partyDetails, false, null, salesRep)} {...this.props}
      />;
    }

    const hasEngines = get(partyDetails, 'inventory.engines', 0) > 0;
    const params = get(this.props, 'match.params', {});
    const existingParams = Object.entries(params).filter(([key, value]) => key !== 'owner' && value);
    if (this.props.search.count === 0 && existingParams.length === 0 && hasEngines) {
      return <Redirect to={
        {
          pathname: getPartyEnginesBrandedSRP(partyDetails),
          state: { statusCode: 302 } }
      } {...this.props} />;
    }

    //If the party is not found we redirect to the dealers search page
    if (this.props.statusCode === 404) {
      const redirectUrl = generateSearchPath({});
      return <Redirect to={{ pathname: redirectUrl }} {...this.props} />;
    }

    return (
      <DefaultLayout {...this.props} pageType="BrandedSearch">
        {this.props.errors
          ? <ErrorMessage {...this.props} />
          : <MainContent {...this.props} partyDetails={partyDetails} mode={MainContent.MODES.branded} />
        }
      </DefaultLayout>
    );

  }
}

const mapStateToProps = (state) => ({
  params: get(state.app, 'params', {}),
  search: get(state.app, 'data.search', {}),
  facets: get(state.app, 'data.facets', {}),
  partyDetails: get(state.app, 'data.partyDetails', {}),
  spotlightDetails: get(state.app, 'data.spotlightDetails', {}),
  isWorking: state.app.isWorking,
  componentWorking: state.app.componentWorking,
  success: state.app.success,
  errors: state.app.errors,
  message: state.app.message,
  statusCode: state.app.statusCode,
  isBoom: get(state.app, 'data.isBoom', false)
});

BrandedSearch.contextType = PortalConfigContext;

BrandedSearch.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  errors: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  search: PropTypes.shape({
    count: PropTypes.number,
  }).isRequired,
  partyDetails: PropTypes.shape({
    bannerPath: PropTypes.string,
    description: PropTypes.string,
    logoPath: PropTypes.string,
    name: PropTypes.string,
    displayName: PropTypes.string,
    multiLangDescriptions: PropTypes.shape({
      description: PropTypes.string,
      englishDescription: PropTypes.string,
      danishDescription: PropTypes.string,
      spanishDescription: PropTypes.string,
      italianDescription: PropTypes.string,
      germanDescription: PropTypes.string,
      dutchDescription: PropTypes.string,
      norwegianDescription: PropTypes.string,
      finnishDescription: PropTypes.string,
      swedishDescription: PropTypes.string,
    }),
    inventory: PropTypes.shape({
      boats: PropTypes.number,
      engines: PropTypes.number,
    }),
    statusId: PropTypes.string,
  }).isRequired,
  spotlightDetails: PropTypes.shape({
    party: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      brokerSpotlight: PropTypes.bool,
      details: PropTypes.shape({
        brokerImageUrl: PropTypes.string,
        brokerName: PropTypes.string,
        normalizedBrokerName: PropTypes.string,
        brokerageLogoUrl: PropTypes.string,
        parentName: PropTypes.string,
        brokerSpotlightConnection: PropTypes.bool,
        brokerSpotlightId: PropTypes.string
      })
    })
  }),
  success: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
  isBoom: PropTypes.bool,
  statusCode: PropTypes.number
};

// we create a function that will run only once bypassing multiple renders.
const justFirsTime = runOnce();

export default withCookies(connect(
  mapStateToProps,
  null
)(frontloadConnect(({ dispatch, history, location, cookies, debug = process.env.REACT_APP_LOCAL_DEBUG }) => {
  const otherParams = getParamsFromLocationAndHistoryQueryString({
    location,
    history
  });
  otherParams.ownerDetails = true;
  if (utils.isServer() || actions.shouldGetData(location)) {
    return dispatch(actions.getData(location.pathname, cookies.cookies, otherParams));
  }

  if (justFirsTime() && debug) {
    return dispatch(actions.getData(location.pathname, cookies.cookies, otherParams));
  }
}, {
  onUpdate: true,
  onMount: true
})(BrandedSearch)));
