import React, { useState, useEffect, useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  formatPrice,
  formatWithDecimal,
  formatPriceWithDecimal,
} from '@dmm/lib-common/lib/formatting';

import { calculateLoan } from './helperCalculator';

import './Calculator.styles.css';
import {useIntl} from 'react-intl';
import { getMessages } from '../../tppServices/translations/messages';
import { PortalConfigContext } from '../../config/portal';
import { getBoatLoanConstants } from '../../constants/BoatLoans';

export const Calculator = ({
  setGenericEvent,
  tridentLoanAmount,
  tridentTeaserRate,
  tridentTermInMonths,
  ctaLink,
  showMonthlyCalculatorOnly,
  dataTestId = 'calculator-component',
  isBDP = false,
  hideTridentFeatures = false
}) => {
  const messages = getMessages();
  const { main} = messages.boatLoansLeads.getPreQualified.calculator;
  const context = useContext(PortalConfigContext);
  const boatLoansConstants = getBoatLoanConstants(context);
  const { MONTHLY_PAYMENT_CALCULATOR, LOAN_AMOUNT_CALCULATOR } = boatLoansConstants;
  const [calculator, setCalculator] = useState({
    mode: MONTHLY_PAYMENT_CALCULATOR,
    autoCalculate: false,
    showErrorOnCalculate: {
      loanAmountOrMonthlyPayment: false,
      loanTermYears: false,
      loanTermMonths: false,
      interestRate: false,
    },
    formFields: {
      purchasePrice: '$0.00',
      downPayment: '$0.00',
      monthlyPayment: '',
      desiredMonthlyPayment: '$0.00',
      loanTermYears: '',
      loanTermMonths: '',
      interestRate:
        typeof tridentTeaserRate === 'undefined'
          ? ''
          : tridentTeaserRate.toString(),
      loanAmount: ''
    },
    result: {
      totalLoanAmount: '$0.00',
      monthlyPayment: '$0.00',
      totalPrincipal: '$0.00',
      totalInterest: '$0.00',
    },
  });
  const intl = useIntl();
  const formatMessage = intl.formatMessage;
  useEffect(() => {
    let priceAmount = '$0.00';
    let termYears;
    let formattedRate;
    let termMonths;
    let autoCalc = false;

    if (tridentLoanAmount) {
      priceAmount = formatPriceWithDecimal(tridentLoanAmount, 'USD', 'en-US', 2);
      termYears = String(tridentLoanAmount >= 50000 ? 20 : 15);
      autoCalc = true;
    }

    if (tridentTeaserRate) {
      formattedRate = formatWithDecimal(
        tridentTeaserRate,
        'en-US',
        2,
        true
      );
    }

    if (tridentTermInMonths) {
      termMonths = String(tridentTermInMonths);
      if (!termYears) {
        termYears = String(tridentTermInMonths / 12);
      }
    }

    setCalculator({
      ...calculator,
      autoCalculate: autoCalc,
      formFields: {
        ...calculator.formFields,
        purchasePrice: priceAmount,
        loanAmount: priceAmount,
        ...(formattedRate && { interestRate: formattedRate }),
        ...(termYears && { loanTermYears: termYears }),
        ...(termMonths && { loanTermMonths: termMonths })
      },
      result: {
        ...calculator.result,
        totalPrincipal: priceAmount
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tridentLoanAmount, tridentTeaserRate, tridentTermInMonths]);

  useEffect(() => {
    if (calculator.autoCalculate && tridentTeaserRate) {
      const triggerAutoCalculate = async () => {
        await handleCalculateClick();
      };
      triggerAutoCalculate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculator.autoCalculate, tridentTeaserRate]);

  const removeLastOccurrence = (str, char) => {
    const lastIndexOfL = str.lastIndexOf(char);
    return str.slice(0, lastIndexOfL) + str.slice(lastIndexOfL + 1);
  };

  const validateDownPayment = (processedValue) =>{
    const purchasePriceNumber = Number(calculator.formFields.purchasePrice.replace(/[^0-9.-]+/g, ''));
    let downPaymentNumber = Number((processedValue).replace(/[^0-9.-]+/g, ''));
    if (downPaymentNumber > purchasePriceNumber) {
      return calculator.formFields.purchasePrice;
    }
    return processedValue;
  };

  const setCalculatorFormValue = (name, value) => {
    let processedValue = value;

    if (name === 'purchasePrice' || name === 'desiredMonthlyPayment' || name === 'downPayment') {
      const numOfPeriods = (value.match(/\./g) || []).length;
      let decimalPart = '';

      if (numOfPeriods > 1) {
        processedValue = removeLastOccurrence(processedValue, '.');
      }
      decimalPart =
        processedValue.indexOf('.') > -1
          ? processedValue.substring(processedValue.indexOf('.'))
          : '';
      processedValue =
        formatPrice(
          processedValue.replace(/[^0-9.]/g, '').replace(/\..*/, ''),
          'USD',
          'en-US'
        ) + decimalPart.replace(/[^0-9.]/g, '');

      if (!processedValue) {
        processedValue = '$0';
      }

      if (name === 'downPayment'){
        processedValue = validateDownPayment(processedValue);
      }

    } else if (name === 'loanTermYears' || name === 'loanTermMonths') {
      processedValue = value.replace(/[^0-9]/g, '');
    } else {
      const numOfPeriods = (value.match(/\./g) || []).length;
      processedValue =
        numOfPeriods > 1
          ? removeLastOccurrence(value, '.')
          : value.replace(/[^0-9.]/g, '');
    }

    let updatedFormValues = {
      ...calculator,
      formFields: {
        ...calculator.formFields,
        [name]: processedValue,
      },
    };

    if (name === 'loanTermYears') {
      updatedFormValues = {
        ...updatedFormValues,
        formFields: {
          ...updatedFormValues.formFields,
          loanTermMonths: processedValue.length
            ? (parseInt(processedValue) * 12).toString()
            : '',
        },
      };
    } else if (name === 'loanTermMonths') {
      updatedFormValues = {
        ...updatedFormValues,
        formFields: {
          ...updatedFormValues.formFields,
          loanTermYears: processedValue.length
            ? parseInt(processedValue.charAt(processedValue.length - 1)) <= 5
              ? Math.floor(parseInt(processedValue) / 12).toString()
              : Math.ceil(parseInt(processedValue) / 12).toString()
            : '',
        },
      };
    }
    setCalculator(updatedFormValues);
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    setCalculatorFormValue(name, value);
  };

  const handleBlurInterestRate = async (event) => {
    const { name, value } = event.target;

    const processedValue = formatWithDecimal(
      value.replace(/[^0-9.]/g, ''),
      'en-US',
      2,
      true
    );
    setCalculatorFormValue(name, processedValue);
  };

  const handleBlurMonthlyPaymentLoanAmount = async (event) => {
    const { name, value } = event.target;

    const processedValue = formatPriceWithDecimal(
      value.replace(/[^0-9.]/g, ''),
      'USD',
      'en-US',
      2
    );
    setCalculator({
      ...calculator,
      formFields: {
        ...calculator.formFields,
        [name]: processedValue,
      },
    });
  };

  const handleMonthlyPaymentModeSelection = async () => {
    if (calculator.mode === LOAN_AMOUNT_CALCULATOR) {

      setCalculator({
        ...calculator,
        mode: MONTHLY_PAYMENT_CALCULATOR,
        formFields: {
          ...calculator.formFields
        },
      });
    }
  };

  const handleLoanAmountModeSelection = async () => {
    if (calculator.mode === MONTHLY_PAYMENT_CALCULATOR) {
      setCalculator({
        ...calculator,
        mode: LOAN_AMOUNT_CALCULATOR,
        formFields: {
          ...calculator.formFields,
          desiredMonthlyPayment:
            calculator.result.monthlyPayment !== '$0.00' && calculator.formFields.desiredMonthlyPayment === '$0.00'
              ? calculator.result.monthlyPayment
              : calculator.formFields.desiredMonthlyPayment,
        },
      });
    }
  };

  const updateLoanAmount = (loanAmount) => {
    let updatedCalculator = {
      ...calculator,
      formFields: {
        ...calculator.formFields,
        loanAmount
      },
    };
    setCalculator(updatedCalculator);
  };

  const updateDownPayment = (downPayment) => {
    let updatedCalculator = {
      ...calculator,
      formFields: {
        ...calculator.formFields,
        downPayment
      },
    };
    setCalculator(updatedCalculator);
  };

  const handleCalculateClick = async () => {
    const blankLoanAmountOrMonthlyPayment = !purchasePrice?.replace(/ /g, '').length > 0;
    const blankLoanTermYears = !loanTermYears?.replace(/ /g, '').length > 0;
    const blankLoanTermMonths = !loanTermMonths?.replace(/ /g, '').length > 0;
    const blankInterestRate = !interestRate?.replace(/ /g, '').length > 0;

    const formMissingFields =
      blankLoanAmountOrMonthlyPayment ||
      blankLoanTermYears ||
      blankLoanTermMonths ||
      blankInterestRate;

    setCalculator({
      ...calculator,
      showErrorOnCalculate: {
        loanAmountOrMonthlyPayment: blankLoanAmountOrMonthlyPayment,
        loanTermYears: blankLoanTermYears,
        loanTermMonths: blankLoanTermMonths,
        interestRate: blankInterestRate,
      },
    });

    if (!formMissingFields) {
      const rate = interestRate;
      const term = loanTermMonths;
      const loanAmountOrMonthlyPayment = calculator?.mode ===  MONTHLY_PAYMENT_CALCULATOR
        ? calculator?.formFields?.loanAmount
        : calculator?.formFields?.desiredMonthlyPayment;

      setGenericEvent(
        'loan calculation',
        'calculate - click',
        calculator.mode === MONTHLY_PAYMENT_CALCULATOR
          ? 'monthly payment'
          : 'total loan amount'
      );

      const { monthlyPayment, totalPrincipal, totalInterest, totalLoanAmount } =
        calculateLoan(calculator.mode)(rate, term, loanAmountOrMonthlyPayment);

      if (calculator.mode === MONTHLY_PAYMENT_CALCULATOR){
        setCalculator({
          ...calculator,
          autoCalculate: false,
          result: {
            ...calculator.result,
            totalLoanAmount,
            monthlyPayment,
            totalInterest,
          },
        });
      }
      else {
        setCalculator({
          ...calculator,
          autoCalculate: false,
          result: {
            ...calculator.result,
            totalPrincipal,
            totalInterest,
          },
        });
      }
    }
  };

  const clearErrors = async () => {
    setCalculator({
      ...calculator,
      showErrorOnCalculate: {
        loanAmountOrMonthlyPayment: false,
        loanTermYears: false,
        loanTermMonths: false,
        interestRate: false,
      },
    });
  };

  const {
    mode,
    showErrorOnCalculate,
    formFields: {
      purchasePrice,
      downPayment,
      loanAmount,
      desiredMonthlyPayment,
      loanTermYears,
      loanTermMonths,
      interestRate,
    },
    result: { totalLoanAmount, monthlyPayment, totalPrincipal, totalInterest },
  } = calculator;

  return (
    <div className="calc-calculator-container" id="calculator-component" data-testid={dataTestId}>
      <div className="calc-calculator-sub-container">
        {!showMonthlyCalculatorOnly && (
          <div className="calc-calculator-mode-selector">
            <div
              className={classnames('calc-calculator-selector-button monthly-mode', {
                selected: mode === MONTHLY_PAYMENT_CALCULATOR,
              })}
              onClick={handleMonthlyPaymentModeSelection}
              data-testid="monthly-payment-button"
            >
              {formatMessage(main.monthlyPayment)}
            </div>
            <div
              className={classnames(
                'calc-calculator-selector-button loan-amount-mode',
                {
                  selected: mode === LOAN_AMOUNT_CALCULATOR,
                }
              )}
              onClick={handleLoanAmountModeSelection}
              data-testid="loan-amount-button"
            >
              {formatMessage(main.totalLoanAmount)}
            </div>
          </div>
        )}
        <div className="calc-calculator-body">
          <CalculatorForm
            handleInputChange={handleInputChange}
            handleBlurInterestRate={handleBlurInterestRate}
            handleBlurMonthlyPaymentLoanAmount={
              handleBlurMonthlyPaymentLoanAmount
            }
            updateLoanAmount={updateLoanAmount}
            updateDownPayment={updateDownPayment}
            handleCalculateClick={handleCalculateClick}
            clearErrors={clearErrors}
            purchasePrice={purchasePrice}
            downPayment={downPayment}
            loanAmount={loanAmount}
            desiredMonthlyPayment={desiredMonthlyPayment}
            loanTermYears={loanTermYears}
            loanTermMonths={loanTermMonths}
            interestRate={interestRate}
            showErrorOnCalculate={showErrorOnCalculate}
            mode={mode}
          />
          <CalculatorResult
            totalLoanAmount={totalLoanAmount}
            monthlyPayment={monthlyPayment}
            totalPrincipal={totalPrincipal}
            totalInterest={totalInterest}
            mode={mode}
            ctaLink={ctaLink}
            isBDP={isBDP}
            hideTridentFeatures={hideTridentFeatures}
          />
        </div>
      </div>
    </div>
  );
};

Calculator.propTypes = {
  setGenericEvent: PropTypes.func.isRequired,
  tridentLoanAmount: PropTypes.number,
  tridentTeaserRate: PropTypes.any,
  tridentTermInMonths: PropTypes.number,
  ctaLink: PropTypes.string,
  showMonthlyCalculatorOnly: PropTypes.bool,
  dataTestId: PropTypes.string,
  isBDP: PropTypes.bool,
  hideTridentFeatures: PropTypes.bool,
};

const CalculatorForm = ({
  handleInputChange,
  handleBlurInterestRate,
  handleBlurMonthlyPaymentLoanAmount,
  handleCalculateClick,
  clearErrors,
  updateLoanAmount,
  updateDownPayment,
  purchasePrice,
  downPayment,
  loanAmount,
  desiredMonthlyPayment,
  loanTermYears,
  loanTermMonths,
  interestRate,
  showErrorOnCalculate,
  mode,
}) => {
  const intl = useIntl();
  const formatMessage = intl.formatMessage;
  const context = useContext(PortalConfigContext);
  const boatLoansConstants = getBoatLoanConstants(context);
  const { MONTHLY_PAYMENT_CALCULATOR, LOAN_AMOUNT_CALCULATOR } = boatLoansConstants;
  const messages = getMessages();
  const { form } = messages.boatLoansLeads.getPreQualified.calculator;
  useEffect(() => {
    const purchasePriceNumber = Number(purchasePrice.replace(/[^0-9.-]+/g, ''));
    let downPaymentNumber = Number(downPayment.replace(/[^0-9.-]+/g, ''));
    if (downPaymentNumber > purchasePriceNumber){
      downPaymentNumber = purchasePriceNumber;
      const downPaymentFormatted = formatPriceWithDecimal(downPayment, 'USD', 'en-US', 2);
      updateDownPayment(downPaymentFormatted);
    }
    const loanAmount = formatPriceWithDecimal(purchasePriceNumber - downPaymentNumber, 'USD', 'en-US', 2);
    updateLoanAmount(loanAmount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchasePrice, downPayment]);

  return (
    <div className="calc-calculator-form">
      <form>
        <fieldset className="form-fieldset">
          {mode === MONTHLY_PAYMENT_CALCULATOR && (
            <>
              <label className="calc-form-label" htmlFor="loan-amount-or-monthly-payment">
                {formatMessage(form.purchasePrice)}
              </label>
              <input
                className="calc-form-data input"
                type="text"
                id="loan-amount-or-monthly-payment"
                name="purchasePrice"
                placeholder={formatMessage(form.purchasePricePlaceholder)}
                onChange={handleInputChange}
                onFocus={clearErrors}
                onBlur={handleBlurMonthlyPaymentLoanAmount}
                value={purchasePrice}
                data-testid="loan-amount-or-payment-form"
              />

              <label className="calc-form-label" htmlFor="downPayment">
                {formatMessage(form.downPayment)}
              </label>
              <input
                className="calc-form-data input"
                type="text"
                id="loan-downpayment"
                name="downPayment"
                placeholder={formatMessage(form.downPaymentPlaceholder)}
                onChange={handleInputChange}
                onFocus={clearErrors}
                onBlur={handleBlurMonthlyPaymentLoanAmount}
                value={downPayment}
                data-testid="loan-downpayment-form"
                aria-label="down payment"
              />

              <label className="calc-form-label calc-real-loan-amount-label" htmlFor="loan-downpayment">
                {formatMessage(form.loanAmount)}
              </label>
              <div className="calc-real-loan-amount">
                {loanAmount}
              </div>
            </>
          )}
          {mode === LOAN_AMOUNT_CALCULATOR && (
            <>
              <label className="calc-form-label" htmlFor="loan-amount-or-monthly-payment">
                {formatMessage(form.desiredMonthlyPayment)}
              </label>
              <input
                className="calc-form-data input"
                type="text"
                id="loan-amount-or-monthly-payment"
                name="desiredMonthlyPayment"
                placeholder={formatMessage(form.desiredMonthlyPaymentPlaceholder)}
                onChange={handleInputChange}
                onFocus={clearErrors}
                onBlur={handleBlurMonthlyPaymentLoanAmount}
                value={desiredMonthlyPayment}
                data-testid="loan-amount-or-payment-form"
              />
            </>
          )}
          <br />
          <div
            className="calc-free-text-input-error"
            data-testid="calculate-button-validation-error"
            hidden={!showErrorOnCalculate.loanAmountOrMonthlyPayment}
          >
            {formatMessage(form.missingRequiredField)}
          </div>
          <div className="loan-term-wrapper">
            <div className="loan-term-item">
              <label className="calc-form-label loan-amount-or-monthly-payment" htmlFor="loan-term-years">
                {formatMessage(form.loanTermYears)}
              </label>
              <input
                className="calc-form-data input years"
                type="text"
                id="loan-term-years"
                name="loanTermYears"
                placeholder={formatMessage(form.loanTermYearsPlaceholder)}
                onChange={handleInputChange}
                onFocus={clearErrors}
                value={loanTermYears}
                data-testid="loan-term-years-form"
              />
              <div
                className="calc-free-text-input-error"
                data-testid="calculate-button-validation-error"
                hidden={!showErrorOnCalculate.loanTermYears}
              >
                {formatMessage(form.missingRequiredField)}
              </div>
            </div>
            <div className="calc-or-text">Or</div>
            <div className="loan-term-item">
              <label className="calc-form-label" htmlFor="loan-term-months">
                {formatMessage(form.loanTermMonths)}
              </label>
              <input
                className="calc-form-data input"
                type="text"
                id="loan-term-months"
                name="loanTermMonths"
                placeholder={formatMessage(form.loanTermMonthsPlaceholder)}
                onChange={handleInputChange}
                onFocus={clearErrors}
                value={loanTermMonths}
                data-testid="loan-term-months-form"
              />
              <div
                className="calc-free-text-input-error"
                data-testid="calculate-button-validation-error"
                hidden={!showErrorOnCalculate.loanTermMonths}
              >
                {formatMessage(form.missingRequiredField)}
              </div>
            </div>
          </div>
          <label className="calc-form-label interest-rate" htmlFor="interest-rate">
            {formatMessage(form.interestRate)}
          </label>
          <div className="calc-calculate-row">
            <div className="calc-calculate-interest-row" >
              <input
                className="calc-form-data input"
                type="text"
                id="interest-rate"
                name="interestRate"
                placeholder={formatMessage(form.interestRatePlaceholder)}
                onChange={handleInputChange}
                onFocus={clearErrors}
                onBlur={handleBlurInterestRate}
                value={interestRate}
                data-testid="interest-rate-form"
              />
              <div className="calc-percent-sign">%</div>
            </div>
            <div
              className="calc-free-text-input-error interest-rate"
              data-testid="calculate-button-validation-error"
              hidden={!showErrorOnCalculate.interestRate}
            >
              {formatMessage(form.missingRequiredField)}
            </div>
            <div
              className="calc-calculate-button"
              data-testid="calculate-button"
              onClick={handleCalculateClick}
            >
              <span className="cta">{formatMessage(form.calculateButton)}</span>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

CalculatorForm.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleBlurInterestRate: PropTypes.func.isRequired,
  handleBlurMonthlyPaymentLoanAmount: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  updateLoanAmount: PropTypes.func.isRequired,
  purchasePrice: PropTypes.string,
  downPayment: PropTypes.string,
  desiredMonthlyPayment: PropTypes.string,
  loanAmount: PropTypes.string,
  loanTermYears: PropTypes.string,
  loanTermMonths: PropTypes.string,
  interestRate: PropTypes.string,
  mode: PropTypes.string.isRequired,
  showErrorOnCalculate: PropTypes.shape({
    loanAmountOrMonthlyPayment: PropTypes.bool.isRequired,
    loanTermYears: PropTypes.bool.isRequired,
    loanTermMonths: PropTypes.bool.isRequired,
    interestRate: PropTypes.bool.isRequired,
  }),
  handleCalculateClick: PropTypes.func,
  updateDownPayment: PropTypes.func,
};

const CalculatorResult = ({
  totalLoanAmount,
  totalPrincipal,
  monthlyPayment,
  mode,
  ctaLink,
  isBDP,
  hideTridentFeatures
}) => {
  const [customStyle, setCustomStyle] = useState({});
  const intl = useIntl();
  const formatMessage = intl.formatMessage;
  const context = useContext(PortalConfigContext);
  const boatLoansConstants = getBoatLoanConstants(context);
  const { MONTHLY_PAYMENT_CALCULATOR, LOAN_AMOUNT_CALCULATOR, TRIDENT_APP_URL, EXTERNAL_LINK, CALCULATOR_SMALL_PRINT } = boatLoansConstants;
  const messages = getMessages();
  const { result } = messages.boatLoansLeads.getPreQualified.calculator;
  useEffect(() => {
    const displayAmount =
      mode === MONTHLY_PAYMENT_CALCULATOR ? monthlyPayment : totalLoanAmount;

    if (displayAmount.length > 15) {
      setCustomStyle({ fontSize: '1.8rem'});
    } else if (displayAmount.length > 11) {
      setCustomStyle({ fontSize: '2.46rem'});
    } else {
      setCustomStyle({ fontSize: '3.46rem'});
    }
  }, [monthlyPayment, totalLoanAmount, mode]);

  return (
    <div className="calc-calculator-summary" id="calc-summary">
      <div className="calc-summary-wrapper">
        {mode === MONTHLY_PAYMENT_CALCULATOR && (
          <>
            <div className="calc-summary-title">{formatMessage(result.monthlyPayment)}</div>
            <div
              className="calc-summary-data calc-monthly-payment"
              data-testid="loan-amount-or-monthly-payment"
              id="summary-data-monthly-payment"
              style={customStyle}
            >
              {monthlyPayment}
            </div>
          </>
        )}
        {mode === LOAN_AMOUNT_CALCULATOR && (
          <>
            <div className="calc-summary-title">{formatMessage(result.totalLoanAmount)}</div>
            <div
              className="calc-summary-data calc-loan-amount"
              data-testid="loan-amount-or-monthly-payment"
              id="summary-data-loan-amount"
              style={customStyle}
            >
              {totalPrincipal}
            </div>
          </>
        )}

        {!hideTridentFeatures && (
          <>
            <div className="calc-summary-data calc-payment-label">
              {formatMessage(result.readyNextStep)}
            </div>
            <div className="calc-summary-button-row" data-testid="calc-summary-buttons">
              <div className="calc-summary-apply-button">
                <a
                  href={ctaLink || TRIDENT_APP_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-reporting-event-category="loan calculation"
                  data-reporting-event-action="Apply Now - click"
                  data-reporting-event-label="Apply Now"
                  data-testid="apply-now-button-link"
                  data-reporting-click-internal-link-type="click"
                  data-reporting-click-internal-link-id="payment calculator - apply now"
                >
                  <span className="cta-icon"><img alt="link arrow" src={EXTERNAL_LINK} /></span>
                  <span className="cta">{formatMessage(isBDP ? result.getPreQualButton : result.applyNowButton)}</span>
                </a>
              </div>
            </div>
          </>
        )}

        {isBDP &&
          (<div
            className="calc-calculator-footer-container"
            data-testid="calc-calculator-footer-container"
          >
            <div className="calc-calculator-footer">
              {formatMessage(result.disclaimerBDP)}
            </div>
          </div>
          )
        }
      </div>
      <div className="calc-summary-small-print">{CALCULATOR_SMALL_PRINT}</div>
    </div>
  );
};

CalculatorResult.propTypes = {
  totalLoanAmount: PropTypes.string.isRequired,
  monthlyPayment: PropTypes.string.isRequired,
  totalPrincipal: PropTypes.string.isRequired,
  totalInterest: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  ctaLink: PropTypes.string,
  hideTridentFeatures: PropTypes.bool,
  isBDP: PropTypes.bool,
};
