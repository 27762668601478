import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PaginationItem from '../../../../components/PaginationItem';
import {
  generateSearchPath,
  getDefaultParams
} from '../../../../utils/urlHelpers/boats';
import {
  generateBrandedOemSearchPath,
  getDefaultParams as getDefaultOemParams
} from '../../../../utils/urlHelpers/oem';
import get from 'lodash/get';
import { getPaginationData } from '../../../../utils/paginationHelper';
export default class ResultsPagination extends PureComponent {
  render() {
    let { maxPages, onClick, isBranded } = this.props;
    let count = get(this.props, 'search.count', 28);
    let pageParams = get(this.props, 'match.params', {});
    let params = isBranded
      ? getDefaultOemParams(pageParams)
      : getDefaultParams(pageParams);

    let pagination = getPaginationData(params, count, maxPages, 5);
    let pageSize = pagination.pageSize;

    const urlGenerationFunction = isBranded
      ? generateBrandedOemSearchPath
      : generateSearchPath;

    return (
      <ul className="pagination">
        <PaginationItem
          key="first"
          onClick={onClick}
          disabled={pagination.isFirstPage}
          type="prev"
          href={
            !pagination.isFirstPage
              ? urlGenerationFunction(
                  { page: pagination.page - 1, pageSize },
                  params
                )
              : null
          }
          nofollow={isBranded}
        >
          &lt;
        </PaginationItem>

        {pagination.visibleNumbers.map((value) => (
          <PaginationItem
            key={value}
            onClick={onClick}
            href={urlGenerationFunction({ page: value, pageSize }, params)}
            active={pagination.page === value}
            nofollow={isBranded}
          >
            {value}
          </PaginationItem>
        ))}
        <PaginationItem
          key="last"
          onClick={onClick}
          disabled={pagination.isLastPage}
          type="next"
          href={
            !pagination.isLastPage
              ? urlGenerationFunction(
                  { page: pagination.page + 1, pageSize },
                  params
                )
              : null
          }
          nofollow={isBranded}
        >
          &gt;
        </PaginationItem>
      </ul>
    );
  }
}

ResultsPagination.defaultProps = {
  maxPages: 357
};

ResultsPagination.propTypes = {
  maxPages: PropTypes.number,
  onClick: PropTypes.func,
  isBranded: PropTypes.bool
};
