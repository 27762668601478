import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { normalizeString } from '@dmm/lib-common/lib/formatting';
import {
  hyphenateUrlComponents,
  generateSearchPath
} from '../../../../../../utils/urlHelpers/boats';
import { translateMake } from '../../../../../../utils/commonHelper';
import {
  ToolSetOptionsItem,
  ToolSetOptions
} from '../../../../../../components/ToolSet';
import {
  CollapsibleContent,
  CollapsibleHeader,
  Collapsible
} from '../../../../../../components/Collapsible';
import Link from '../../../../../../components/SEO/Link';
import get from 'lodash/get';
import includes from 'lodash/includes';
import {
  getFilteredFacet,
  toggleSubFacet
} from '../../../../../../utils/multiFacetHelper';
import { FilterTypeAhead } from '../..';

import { injectIntl } from 'react-intl';

import { generateBrandedOemSearchPath } from '../../../../../../utils/urlHelpers/oem';
import { yieldToMain } from '../../../../../../utils';
import { setSearchTrackingClean, setGenericEventClean } from '../../../../../../store/actions/dataLayer';

import {useTPPServices} from '../../../../../../tppServices/tppDIHooks';
import { getMessages } from '../../../../../../tppServices/translations/messages';

const FilterModel = ( {
  make,
  models = [],
  position,
  makeModels = {},
  maxModelCount/* = bts().MAX_MODEL_COUNT*/,
  params,
  handleDataChange,
  intl: { formatMessage: t },
  pageType
} ) => {
  const dispatch = useDispatch();
  const {boatsConstants} = useTPPServices();
  maxModelCount = maxModelCount ?? boatsConstants.MAX_MODEL_COUNT;
  const { modal = [] } = params;
  const slugMake = normalizeString(hyphenateUrlComponents(make.value));
  const allMakeModels = getFilteredFacet(models, []);
  let showAllModels = modal.includes('model');
  const [selectedMakeModels, setSelectedMakeModels] = useState(makeModels);
  const messages = getMessages();

  useEffect(() => {
    handlePropsUpdate();
  }, [makeModels]);

  const handleToggleModel = async (make, model) => {
    handleTracking(make, model, selectedMakeModels);
    let models = toggleSubFacet(make, model, selectedMakeModels, true);
    setSelectedMakeModels(models);
    await yieldToMain();
    dispatch({ type: 'GET_DATA_LOADING' });
    await yieldToMain();
    handleDataChange('makeModel', models);
  };

  const handleTracking = (make, model, selected) => {
    let selectedModels = get(selected, make, []);
    if (includes(selectedModels, model)) {
      setGenericEventClean(`model removed - ${model}`);
    } else {
      let models = [];
      models.push(model);
      let trackingModel = { [make]: models };
      setSearchTrackingClean({ makeModel: trackingModel });
    }
  };

  const handlePropsUpdate = () => {
      setSelectedMakeModels(makeModels);
  };

  const renderModels = (models, selectionCallback) => {
    const slugMake = normalizeString(hyphenateUrlComponents(make.value));

    const generateHref =
      pageType === boatsConstants.PAGE_TYPES.brandedOemSearch
        ? generateBrandedOemSearchPath
        : generateSearchPath;

    return (
      <ToolSetOptions>
        {models.map((model, i) => {
          const slugModel = normalizeString(model.value);

          const changes = {
            makeModel: toggleSubFacet(
              slugMake,
              slugModel,
              selectedMakeModels,
              true
            ),
            ...(pageType === boatsConstants.PAGE_TYPES.brandedOemSearch
              ? { brand: slugMake }
              : {})
          };

          const href = generateHref(changes, params, true);

          return (
            <ToolSetOptionsItem
              type="checkbox"
              key={`Model-${slugModel}-${i}`}
              id={`Model-${slugModel}`}
              name={`${t(messages.model)}-${slugMake}-${position}`}
              value={slugModel}
              selected={includes(
                get(selectedMakeModels, slugMake, []),
                slugModel
              )}
              onClick={(value) => {
                handleToggleModel(slugMake, value);
                selectionCallback(value);
              }}
            >
              <Link
                className="model-link"
                href={href}
                onClick={(e) => e.preventDefault()}
                type="facet"
                pageType={pageType}
              >
                {model.value}
              </Link>
            </ToolSetOptionsItem>
          );
        })}
      </ToolSetOptions>
    );
  };

  return (
    <CollapsibleContent
      initialState="open"
      key={`MakeCollapsible-${slugMake}-${position}`}
      id={`MakeCollapsible-${slugMake}-${position}`}
    >
      <CollapsibleHeader priority={0}>
        {t(messages.model, { make: translateMake(make.value) })}
      </CollapsibleHeader>
      <Collapsible>
        <div className="search-filter model">
          <FilterTypeAhead
            items={allMakeModels}
            selectedItems={get(selectedMakeModels, slugMake)}
            id={`model-type-ahead-${slugMake}-${position}`}
            moreMessage="moreModels"
            placeholder={`${t(messages.modelPlaceholder, {
              make: translateMake(make.value)
            })}`}
            max={maxModelCount}
            render={(options, callback) =>
              renderModels(options, callback)
            }
            showAll={showAllModels}
            url={generateSearchPath(params) + 'modal-model/'}
          />
        </div>
      </Collapsible>
    </CollapsibleContent>
  );
};

FilterModel.propTypes = {
  handleDataChange: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  make: PropTypes.shape({
    value: PropTypes.string
  }).isRequired,
  makeModels: PropTypes.object,
  maxMakeCount: PropTypes.number,
  maxModelCount: PropTypes.number,
  models: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      value: PropTypes.string
    })
  ),
  params: PropTypes.shape({
    modal: PropTypes.string
  }).isRequired,
  position: PropTypes.string,
  pageType: PropTypes.object
};

export default injectIntl(FilterModel);
