import { setGenericEventClean } from '../../../../store/actions/dataLayer';
import { formatMultiFacetParam } from '../../../../utils/multiFacetHelper';
import SearchAlertsModal from './components/SearchAlertsModal';
import ConfirmationModal from './components/ConfirmationModal';
import { createSearchAlert } from '../../../../store/actions';

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getConfig } from '../../../../config/portal';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import './styles.css';
import { getMessages } from '../../../../tppServices/translations/messages';

class SearchAlerts extends PureComponent {
  state = {
    trackLength: 0,
    hasSearchCriteria: false,
    showSearchAlertModal: false,
    showConfirmationModal: false
  };

  handleResize = this.handleResize.bind(this);
  handleResize() {
    const winHeight = window.innerHeight;
    const docHeight = this.getDocHeight();
    this.setState({ trackLength: docHeight - winHeight });
  }

  getDocHeight() {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    // cache initial viewport
    this.handleResize();
  }

  componentDidUpdate() {
    const {
      match: { params }
    } = this.props;
    this.setSearchAvailability(params);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  formatSearchParams = () => {
    const formattedParams = cloneDeep(this.props.searchParams);
    formattedParams.facets = formattedParams.facets
      ? formattedParams.facets.split(',')
      : undefined;
    formattedParams.fields = formattedParams.fields
      ? formattedParams.fields.split(',')
      : undefined;
    formattedParams.multiFacetedBoatTypeClass = formatMultiFacetParam(
      formattedParams.multiFacetedBoatTypeClass
    );
    formattedParams.multiFacetedMakeModel = formatMultiFacetParam(
      formattedParams.multiFacetedMakeModel
    );
    return formattedParams;
  };

  getCriteria = (params) => {
    const filters = [];

    for (const [, value] of Object.entries(params)) {
      if (value) {
        filters.push(
          value.replace('-', ' ').replace(',', ' - ').replace('+', ' ')
        );
      }
    }

    return filters.join(', ');
  };

  getSearchAlertBody = (userEmail) => ({
    userEmail: userEmail,
    searchParams: this.formatSearchParams(),
    searchUrl: get(window, 'location.href'),
    criteria: this.getCriteria(get(this.props.match, 'params', {}))
  });

  submitSearchAlert = (userEmail) => {
    setTimeout(() => {
      setGenericEventClean('search alerts', 'save search', 'modal');
    });
    this.toggleSearchAlertsModal();
    this.props.createSearchAlert(
      this.getSearchAlertBody(userEmail),
      this.showConfirmationModal
    );
  };

  toggleSearchAlertsModal = (willOpenModal) => {
    this.setState({ showSearchAlertModal: !this.state.showSearchAlertModal });
    if (willOpenModal) {
      setTimeout(() => {
        setGenericEventClean(
          'search alerts',
          'create search',
          this.props.eventCategory
        );
      });
    }
  };

  showConfirmationModal = () => {
    this.setState({ showConfirmationModal: true });
  };

  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  setSearchAvailability = (params) => {
    const existingParams = Object.entries(params).filter(
      ([key, value]) => key && value
    );
    this.setState({ hasSearchCriteria: existingParams.length === 0 });
  };

  render() {
    const {
      buttonText = '',
      intl: { formatMessage: t }
    } = this.props;
    const { showSearchAlertModal, showConfirmationModal } = this.state;
    const messages = getMessages();

    const enableNewSaveSearch = get(
      getConfig(),
      'supports.enableNewSaveSearch',
      false
    );

    let isDisabled;
    if (!enableNewSaveSearch) {
      isDisabled = this.state.hasSearchCriteria;
    } else {
      isDisabled = false;
    }

    return (
      <div className="search-alerts">
        <button
          className="search-alerts-button"
          disabled={isDisabled}
          onClick={() => this.toggleSearchAlertsModal(true)}
        >
          {buttonText || t(messages.searchAlerts)}
        </button>
        <SearchAlertsModal
          show={showSearchAlertModal}
          close={this.toggleSearchAlertsModal}
          submit={this.submitSearchAlert}
          intl={this.props.intl}
          active={this.props.active}
          makeModel={this.props.makeModel}
          seoMakeInfo={this.props.seoMakeInfo}
          isBranded={this.props.isBranded}
          hasSearchCriteria={this.state.hasSearchCriteria}
        />
        <ConfirmationModal
          show={showConfirmationModal}
          close={this.hideConfirmationModal}
          intl={this.props.intl}
        />
      </div>
    );
  }
}

SearchAlerts.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  eventCategory: PropTypes.string,
  createSearchAlert: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  searchParams: PropTypes.object,
  searchUrl: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.object
  }),
  active: PropTypes.shape({
    /** Selected condition */
    condition: PropTypes.oneOf(['new', 'used']),
    /** Selected engine type */
    engine: PropTypes.string,
    /** Selected seller type */
    forSale: PropTypes.oneOf(['dealer', 'owner']),
    /** Selected fuel types */
    fuelType: PropTypes.arrayOf(PropTypes.string),
    /** Selected hull materials */
    hullMaterial: PropTypes.arrayOf(PropTypes.string),
    /** Selected length range */
    length: PropTypes.shape({
      max: PropTypes.string,
      min: PropTypes.string
    }),
    /** Selected makes and models */
    makeModel: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    /** Selected types and classes */
    multiFacetedBoatTypeClass: PropTypes.shape({
      power: PropTypes.arrayOf(PropTypes.string),
      sail: PropTypes.arrayOf(PropTypes.string),
      unpowered: PropTypes.arrayOf(PropTypes.string)
    }),
    /** Selected radius: exact | number */
    radius: PropTypes.string,
    /** Selected sort: distance:asc, length:desc, etc. */
    sort: PropTypes.string,
    /** Selected subdivision initials */
    subdivision: PropTypes.string,
    /** Selected year range */
    year: PropTypes.shape({
      max: PropTypes.string,
      min: PropTypes.string
    })
  }),
  /** Available makes */
  makeModel: PropTypes.arrayOf(
    PropTypes.shape({
      /** Make formatted name */
      value: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      model: PropTypes.arrayOf(
        PropTypes.shape({
          /** Model formatted name */
          value: PropTypes.string.isRequired,
          count: PropTypes.number.isRequired
        })
      )
    })
  ),
  seoMakeInfo: PropTypes.shape({
    make: PropTypes.string.isRequired,
    seoMakeName: PropTypes.string.isRequired
  }),
  isBranded: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    searchParams: get(state, 'app.params', {})
  };
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      createSearchAlert
    },
    dispatch
  )
)(SearchAlerts);
