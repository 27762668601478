import React, { memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { getConfig } from '../../../config/portal';
import { generateBrandedOemSearchPath } from '../../../utils/urlHelpers/oem';
import './styles.css';

const getImagePath = (url) =>
  url.replace(/^.*\/\/[^/]+/, '').replace('/images', '/resize');

const HeroImage = (props) => {
  const { logo, heroImage, name } = useSelector((state) =>
    get(state, 'app.data.brandShowcase', {})
  );
  const makeModel = useSelector((state) =>
    get(state, 'app.data.makeModel', '')
  );

  if (!(logo && heroImage && name)) {
    return null;
  }

  const hostname = get(getConfig(), 'client.imageserver.hostname');
  const logoURL = `${hostname}${getImagePath(logo)}`;
  const heroURL = `${hostname}${getImagePath(heroImage)}`;
  const linkURL = generateBrandedOemSearchPath(
    {},
    { brand: name.toLowerCase().replace(/\s+/g, '-') },
    true
  );

  return (
    <a href={linkURL}>
      <div
        className={classnames('paying-brand-hero-image', {
          mobile: props.isMobile
        })}
        style={{ backgroundImage: `url(${heroURL})` }}
      >
        <div className="paying-brand-logo">
          <img src={logoURL} alt={`${makeModel} logo`} />
        </div>
      </div>
    </a>
  );
};

HeroImage.propTypes = {
  isMobile: PropTypes.bool
};

export default memo(HeroImage);
