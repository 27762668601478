import React from 'react';
import { useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import { yieldToMain } from '../../../../../../../utils';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ToolSetOptions, ToolSetOptionsItem } from '../../../../../../../components/ToolSet';
import { FilterTypeAhead } from '../../..';
import { generateSearchPath } from '../../../../../../../utils/urlHelpers/boats';
import Link from '../../../../../../../components/SEO/Link';


import {useTPPServices} from '../../../../../../../tppServices/tppDIHooks';
import {slugify} from '../../../../../../../utils/urlHelpers/shared';
import { getMessages } from '../../../../../../../tppServices/translations/messages';

const  FilterCountry = ({
  intl: { formatMessage: t },
  selectedCountry: selectedCountryProps,
  countries,
  maxCountriesCount/* = bts().MAX_COUNTRIES_COUNT*/,
  params,
  position,
  handleDataChange: handleDataChangeProps,
  tracking,
  device
}) => {
  const selectedCountry = selectedCountryProps || 'all';
  const dispatch = useDispatch();
  const {boatsConstants} = useTPPServices();
  const messages = getMessages();
  maxCountriesCount = maxCountriesCount ?? boatsConstants.MAX_COUNTRIES_COUNT;
  const showAllCountries = get(params, 'modal', []).includes('country');
  const countriesWithNameTranslation = countries.map(country => ({ value: country.value, filterValue: t(messages.countries[country.value])}));

  const handleDataChange = async (countryCode) =>{
    if (tracking) {
      tracking.facetAdded({ 'country': countryCode });
    }
    await yieldToMain();
    if (device !== 'mobile') {
      dispatch({ type: 'GET_DATA_LOADING' });
    }
    handleDataChangeProps('country', countryCode);
  };


  return (
    <div className="search-filter countries">
      <FilterTypeAhead
        items={countriesWithNameTranslation}
        selectedItems={[slugify(selectedCountry)]}
        id={`country-type-ahead-${position}`}
        placeholder={t(messages.countryPlaceholder)}
        moreMessage="moreCountries"
        max={maxCountriesCount}
        url={generateSearchPath(params) + 'modal-country/'}
        showAll={showAllCountries}
        render={(items, selectionCallback) =>
          <ToolSetOptions>
            { items.map(country => {
              const countryName = t(messages.countries[country.value]);

              return <ToolSetOptionsItem
                key={`Country-${country.value}`}
                id={`Country-${country.value}`}
                name={`Country-${country.value}`}
                value={countryName}
                selected={ selectedCountry === country.value}
                onClick={(value) => {
                  handleDataChange(country.value);
                  selectionCallback(value);
                }}
              >
                <Link className="country-type-link" href={generateSearchPath({country: country.value, city: [], region: '', subdivision: ''}, params, true)} onClick={(e) => e.preventDefault()}>
                  {countryName}
                </Link>
              </ToolSetOptionsItem>;
            })
            }
          </ToolSetOptions>
        }
        renderStatic={() =>
          <ToolSetOptions>
            <ToolSetOptionsItem
              key="country-all"
              id="country-all"
              name="country-all"
              value="all"
              selected={selectedCountry === 'all'}
              onClick={() => {
                handleDataChange('');
              }}
            >
              <Link className="country-type-link" href={generateSearchPath({country: '', city: [], region: '', subdivision: ''}, params, true)} onClick={(e) => e.preventDefault()}>
                {t(messages.allCountries)}
              </Link>
            </ToolSetOptionsItem>
          </ToolSetOptions>}/>
    </div>
  );
};

FilterCountry.propTypes = {
  device: PropTypes.string,
  selectedCountry: PropTypes.string,
  countries: PropTypes.array,
  maxCountriesCount: PropTypes.number,
  params: PropTypes.object,
  position: PropTypes.string,
  handleDataChange: PropTypes.func,
  tracking: PropTypes.shape({
    facetAdded: PropTypes.func,
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired
};

export default injectIntl(FilterCountry);
