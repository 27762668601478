import React, { PureComponent, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { getConfig } from '../../../../config/portal';

// Stylesheets
import './styles.css';

export const getImageSrc = (imgName) => {
  let imageName = imgName || '';
  if (imageName.includes('{{baseUrl}}')) {
    imageName = imageName.replace('{{baseUrl}}', '');
  }
  const imageHost = get(getConfig(), 'client.editorial.baseUrl', '');
  return `${imageHost}${imageName}?w=450&h=450`;
};

export const ImageWithErrorHandler = ({ src, alt }) => {
  const [error, setError] = useState(false);
  return error ? null : <LazyLoadImage src={src} alt={alt} onError={() => setError(true)} />;
};

ImageWithErrorHandler.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string
};

class RelatedBoatArticles extends PureComponent {
  render() {
    const { isFirstPage, relatedBoatArticles } = this.props;

    if (!isFirstPage) {
      return null;
    }

    if (!relatedBoatArticles || relatedBoatArticles.length === 0) {
      return null;
    }

    return (
      <div className="related__articles__container">
        <div className="related__articles__wrapper">
          {relatedBoatArticles.map(article => (
            <div key={article.id} className="related__article__item">
              <div className="related__article__item_image_wrapper">
                <a
                  href={article.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-reporting-click-internal-link-type="related boat article"
                  data-reporting-click-internal-link-id={article.link}
                >
                  <ImageWithErrorHandler
                    src={getImageSrc(article.image)}
                    alt={article.imageAlt}
                  />
                </a>
              </div>
              <p>Reviews</p>
              <h4>
                <a
                  href={article.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-reporting-click-internal-link-type="related boat article"
                  data-reporting-click-internal-link-id={article.link}
                >
                  {article.title}
                </a>
              </h4>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

RelatedBoatArticles.propTypes = {
  isLoading: PropTypes.bool,
  isFirstPage: PropTypes.bool,
  relatedBoatArticles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      link: PropTypes.string,
      image: PropTypes.string,
      imageAlt: PropTypes.string,
      blogId: PropTypes.string
    })
  )
};

export default RelatedBoatArticles;
